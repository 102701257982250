/* eslint-disable prettier/prettier */
import { RecordModelNameType } from '@features/action-records/record-model-name.type';

// TODO all query params are managed by api.ts axios logic
// TODO all methods accepts single attribute request object, and destructuring required field
// to have all params named, and could automatically create url report

export const EndpointUrlBuilder = {
  // ALLOCATIONS
  getAllocations: (): string => `/v2/nmh-allocations-summaries`,
  createAllocation: (): string => `/nmh_allocations`,
  saveAllocation: (allocationId: number | null): string => `/nmh_allocations/${allocationId}`,
  getAllocationSessions: (allocationId: number): string => `/nmh_allocations/${allocationId}/nmh_sessions`,
  // ANNOUNCEMENT
  getAnnouncement: (): string => `/announcement`,
  // ANNUAL SUPPORT CHARGE
  generateAnnualSupportChargeByQuote: (orderId: number): string => `/asc_orders/${orderId}/generate_asc_by_quote`,
  getAnnualSupportChargeList: (orderId: number): string => `/asc_orders/${orderId}/annual_support_charges`,
  createAnnualSupportCharge: (orderId: number): string => `/asc_orders/${orderId}/annual_support_charges`,
  saveAnnualSupportCharge: (orderId: number, annualSupportChargeId: number | null): string => `/asc_orders/${orderId}/annual_support_charges/${annualSupportChargeId}`,
  // APPOINTMENTS
  getAppointmentSummaries: (): string => `/v2/appointments-summaries`,
  getAppointmentInvoiceSummaries: (): string => `/v2/appointments-summaries`,
  getOrderAppointments: (orderId: number): string => `/needs_assessment_orders/${orderId}/needs_assessment_appointments`,
  createOrderAppointment: (orderId: number): string => `/needs_assessment_orders/${orderId}/needs_assessment_appointments`,
  saveOrderAppointment: (orderId: number, appointmentId: number | null): string => `/needs_assessment_orders/${orderId}/needs_assessment_appointments/${appointmentId}`,
  submitAppointmentNoShow: (orderId: number, appointmentId: number | null): string => `/needs_assessment_orders/${orderId}/needs_assessment_appointments/${appointmentId}/no_show`,
  refreshAppointmentElectronicInvoice: (appointmentId: number): string => `/needs_assessment_appointments/${appointmentId}/regenerate_needs_assessment_appointment_electronic_invoice`,
  sendAppointmentElectronicInvoice: (appointmentId: number): string => `/needs_assessment_appointments/${appointmentId}/send_needs_assessment_appointment_electronic_invoice`,
  generateAppointmentElectronicInvoice: (appointmentId: number): string => `/needs_assessment_appointments/${appointmentId}/generate_appointment_xero_invoice`,
  bulkAddAppointmentSupplierInvoice: (): string => `/needs_assessment_appointments/bulk-supplier-invoice-update`,
  bulkGenerateAppointmentXeroInvoice: (): string => `/needs_assessment_appointments/bulk_generate_appointment_xero_invoice`,
  bulkSendAppointmentInvoice: (): string => `/needs_assessment_appointments/bulk_send_needs_assessment_appointment_electronic_invoice`,
  // ASSESMENT ORDERS
  getSensitivePersonalInformationPresence: (orderId: number): string => `/needs_assessment_orders/${orderId}/sensitive_personal_information`,
  requestSensitivePersonalInformation: (orderId: number): string => `/needs_assessment_orders/${orderId}/sensitive_personal_information`,
  saveSensitivePersonalInformation: (orderId: number): string => `/needs_assessment_orders/${orderId}/sensitive_personal_information`,
  getReports: (orderId: number): string => `/needs_assessment_orders/${orderId}/needs_assessment_reports`,
  createReport: (orderId: number): string => `/needs_assessment_orders/${orderId}/needs_assessment_reports`,
  saveReport: (orderId: number, reportId: number | null): string => `/needs_assessment_orders/${orderId}/needs_assessment_reports/${reportId}`,
  sendReport: (orderId: number): string => `/needs_assessment_orders/${orderId}/send_final_report`,
  convertReportToPdf: (orderId: number, reportId: number): string => `/needs_assessment_orders/${orderId}/needs_assessment_reports/${reportId}/convert_to_pdf`,
  // ASSESSMENT CENTRES
  getAssessmentCentresSummaries: (): string => `/assessment-centre-summaries`,
  getAssessmentCentre: (assessmentCentreId: number): string => `/assessment-centre/${assessmentCentreId}`,
  // ASSESSORS
  getAssessors: (): string => `/assessors`,
  getAssessor: (assessorId: number): string => `/assessors/${assessorId}`,
  getAssessorsFeedbackSummaries: (assessorId: number): string => `/assessor-feedback-summaries/${assessorId}`,
  getFilteredAssessorsByOrder: (orderId: number): string => `/assessors/labels_by_disability_types/${orderId}`,
  getAssessorFreeSlots: (assessorId: number): string => `/assessors/${assessorId}/show_crm_free_slots`,
  saveAssessorFreeSlots: (assessorId: number): string => `/assessors/${assessorId}/update_crm_free_slots`,
  getAssessorUtilisation: (): string => `/assessors/availability_report`,
  // CUSTOMERS
  createCustomer: (): string => `/students`,
  saveCustomer: (customerId: number | null): string => `/students/${customerId}`,
  getCustomer: (customerId: number): string => `/students/${customerId}`,
  createOrResetAccount: (customerId: number): string => `/students/${customerId}/create_or_reset_account`,
  deactivateAccount: (customerId: number): string => `/students/${customerId}/deactivate`,
  activateAccount: (customerId: number): string => `/students/${customerId}/activate`,
  // DASHBOARD
  getOrderTypeSummaries: (): string => `/order-type-summaries`,
  getOrderTypeStatusSummaries: (): string => `/order-type-status-summaries`,
  // EMAIL TEMPLATES
  getEmailTemplates: (): string => `/email_templates`,
  // HEPS
  getHeps: (): string => `/heps`,
  getHep: (hepId: number): string => `/heps/${hepId}`,
  // LABELS
  getLabels: (): string => `/v2/labels`,
  // MICROSOFT AUTH
  getMicrosoftLink: (userId: string): string => `/microsoft_authorization/${userId}`,
  requestMicrosoftLogout: (userId: string): string => `/users/${userId}/delete_dynamics_token`,
  // ORDERS
  orderSummaries: (): string => `/v2/order-summaries`,
  astOrderSummaries: (): string => `/v2/needs-assessment-orders-summaries`,
  createOrder: (): string => `/orders`,
  saveOrder: (orderId: number | null): string => `/orders/${orderId}`,
  getOrder: (orderId: number): string => `/orders/${orderId}`,
  getOrderAllocations: (orderId: number): string => `/orders/${orderId}/allocations`,
  deactivateOrder: (orderId: number): string => `/orders/${orderId}/deactivate`,
  activateOrder: (orderId: number): string => `/orders/${orderId}/activate`,
  updateSalesOrderLines: (orderId: number): string => `/sales-order/${orderId}`,
  generateTechnicalOrderInvoiceNumber: (orderId: number): string => `/order/${orderId}/generate_tech_order_xero_invoice`,
  generateTechnicalOrderSalesOrderId: (orderId: number): string => `/order/${orderId}/generate_tech_order_sales_order`,
  getEquipmentInvoices: (): string => `/technical-invoicing-summary`,
  // PRODUCTS
  getProducts: (): string => `/products`,
  getProduct: (productId: number): string => `/products/${productId}`,
  saveProduct: (productId: number): string => `/products/${productId}/update`,
  getSearchProducts: (): string => `/products`,
  // PROGRESS AND CONTACT RECORDS
  getModelProgressRecord: (modelName: RecordModelNameType, id: number): string => `/progress_records/${modelName}/${id}`,
  getModelContactRecord: (modelName: RecordModelNameType, id: number): string => `/contact_records/${modelName}/${id}`,
  createModelContactRecord: (modelName: RecordModelNameType, id: number): string => `/contact_records/${modelName}/${id}`,
  // QUICK FILTER TABS
  getQuickFilterTabs: (): string => `/filter-tabs`,
  // QUOTES
  getQuotes: (): string => `/quotes`,
  getQuote: (quoteInternalId: string): string => `/quotes/${quoteInternalId}`,
  createQuote: (): string => `/quotes`,
  saveQuote: (quoteInternalId?: string | null): string => `/quotes/${quoteInternalId}`,
  // SESSIONS
  createSession: (): string => `/nmh_sessions`,
  saveSession: (sessionId?: number | null): string => `/nmh_sessions/${sessionId}`,
  getSessionSummaries: (): string => `/v2/nmh-sessions-summaries`,
  getSessionInvoiceSummaries: (): string => `/v2/nmh-sessions-summaries`,
  regenerateTimesheet: (sessionId: number): string => `/nmh-sessions/${sessionId}/generate-ts`,
  refreshSessionElectronicInvoice: (sessionId: number): string => `/nmh-sessions/${sessionId}/regenerate_nmh_session_electronic_invoice`,
  sendSessionElectronicInvoice: (sessionId: number): string => `/nmh-sessions/${sessionId}/send_nmh_session_electronic_invoice`,
  generateSessionElectronicInvoice: (sessionId: number): string => `/nmh-sessions/${sessionId}/generate_session_xero_invoice`,
  bulkAddSessionSupplierInvoice: (): string => `/nmh-sessions/bulk-supplier-invoice-update`,
  bulkGenerateSessionXeroInvoice: (): string => `/nmh-sessions/bulk_generate_session_xero_invoice`,
  bulkSendSessionInvoice: (): string => `/nmh-sessions/bulk_send_nmh_session_electronic_invoice`,
  uploadTimesheet: (): string => `/sign_nmh_session`,
  // TECH ORDER DELIVERY SCHEDULE
  deliverySchedule: (): string => `/delivery-schedule`,
  // TUTORS
  getTutors: (): string => `/tutors`,
  getTutor: (tutorId: number): string => `/tutors/${tutorId}`,
  getTutorFeedbackSummaries: (tutorId: number): string => `/tutor-feedback-summaries/${tutorId}`,
  // ZOHO
  getZohoLink: (userId: string): string => `/zoho_authorization/${userId}`,
  requestZohoLogout: (userId: string): string => `/users/${userId}/delete_zoho_token`,
  getZohoFreeSlots: (): string => `/zoho_free_slots`,
};
