import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid';
import { getPaginationResult } from '@features/pagination/get-pagination-result';
import { SortModelRegenerator } from '@models/sort-model-regenerator.type';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { PaginationDefaults } from './paginable.interface';

type UseFetch<T> = (params: URLSearchParams, count: number, skip: number) => ReturnType<typeof getPaginationResult<T>>;

type HookResult<T> = {
  entries: Array<T>;
  totalEntries: number;
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  sortModel: GridSortModel;
  onSortModelChange: (model: GridSortModel) => void;
} & RequestStatusFlags;

/**
 * A custom React hook for handling pagination logic with data fetching and local storage for page size.
 *
 * This hook abstracts the pagination mechanics by integrating URL search parameters for reactive data fetching,
 * maintaining and updating pagination state, and persisting the page size preference in local storage.
 * It uses a provided fetching function tailored for server-side pagination, allowing seamless integration
 * with APIs that support pagination via query parameters.
 *
 * @template T The type of data entries to be fetched and handled.
 * @param {UseFetch<T>} useFetch - A function to fetch paginated data. This function should accept URLSearchParams,
 *                                 and pagination offset and limit, and return a pagination result object.
 * @param {string} key - The localStorage key used to store and retrieve the page size setting.
 * @returns {HookResult<T>} An object containing the paginated entries, total entries count,
 *                          the current pagination model, and a function to handle changes to the pagination model.
 *
 * Usage:
 * This hook should be used in components that require paginated data from a server, with the ability
 * to control pagination through a UI and keep the user's page size preference consistent across sessions.
 */
export function usePagination<T>(
  useFetch: UseFetch<T>,
  key: string,
  sortModelRegenerator?: SortModelRegenerator,
): HookResult<T> {
  const [params, setParams] = useSearchParams();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useLocalStorage<number>(key, PaginationDefaults.perPage, {
    parser: parseInt,
    syncData: true,
  });
  const { entries, pagination, ...flags } = useFetch(params, pageSize, pageSize * page);
  const [totalEntries, setTotalEntries] = useState(pagination.totalEntries);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  useEffect(() => {
    setTotalEntries(pagination.totalEntries);
  }, [pagination.totalEntries]);

  useEffect(() => {
    sortModelRegenerator && sortModelRegenerator(params, setSortModel);
  }, [params, sortModelRegenerator]);

  const onPaginationModelChange = (model: GridPaginationModel): void => {
    setPageSize(model.pageSize);
    setPage(model.page);
  };

  const onSortModelChange = (model: GridSortModel): void => {
    if (model[0]) {
      const [{ field, sort }] = model;
      setParams({ ...params, [field]: sort });
    } else {
      params.delete(sortModel[0].field);
      setParams(params);
    }

    if (!sortModelRegenerator) {
      setSortModel(model);
    }
  };

  const paginationModel = { page, pageSize };

  return {
    entries,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    sortModel,
    onSortModelChange,
    ...flags,
  };
}
